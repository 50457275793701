.reservations-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  margin-top: 64px;
  background: #ffffff;
}

.coming-soon {
  font-size: 4.5rem;
  font-weight: 700;
  color: #333333;
  margin-bottom: 2rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  animation: fadeIn 1.5s ease-in;
}

.coming-soon-message {
  font-size: 1.5rem;
  color: #666666;
  text-align: center;
  max-width: 600px;
  line-height: 1.6;
  padding: 2rem;
  background: #f8f8f8;
  border-radius: 15px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.45);
  animation: slideUp 2s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .coming-soon {
    font-size: 3rem;
  }
  
  .coming-soon-message {
    font-size: 1.2rem;
    padding: 1.5rem;
    margin: 0 1rem;
  }
}