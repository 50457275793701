.gallery-container {
  padding: 2rem;
  margin-top: 64px;
  background: #ffffff;
  min-height: 100vh;
}

.gallery-title {
  color: #333333;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.albums-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.album-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.album-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.album-image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
}

.album-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease;
}

.album-card:hover .album-cover {
  transform: scale(1.05);
}

.album-info {
  padding: 1.5rem;
}

.album-title {
  color: #333333;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.album-description {
  color: #666666;
  font-size: 0.9rem;
  line-height: 1.4;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .gallery-container {
    padding: 1rem;
  }

  .gallery-title {
    font-size: 2rem;
  }

  .albums-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
  }

  .album-image-container {
    height: 180px;
  }
}

/* Album View Styles */
.album-view {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 2rem;
  backdrop-filter: blur(10px);
  margin-top: 2rem;
}

.album-view-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.album-view-header h2 {
  color: white;
  font-size: 2rem;
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: transform 0.3s ease;
}

.close-button:hover {
  transform: scale(1.1);
}

.album-view-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.album-image-item {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.1);
}

.album-image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.album-image-item:hover img {
  transform: scale(1.05);
}

.image-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.5rem;
  margin: 0;
  font-size: 0.9rem;
}

/* Responsive adjustments for album view */
@media (max-width: 768px) {
  .album-view {
    padding: 1rem;
  }

  .album-view-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
  }
} 