.blog-container {
  max-width: 800px;
  margin: 80px auto 40px;
  padding: 0 20px;
  background: #ffffff;
}

.blog-post {
  background: white;
  padding: 3rem;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blog-title {
  font-size: 2.8rem;
  color: #333333;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  font-weight: 700;
}

.blog-meta {
  display: flex;
  gap: 2rem;
  color: #666666;
  margin-bottom: 2.5rem;
  font-size: 1rem;
  border-bottom: 2px solid #eeeeee;
  padding-bottom: 1rem;
}

.blog-content {
  line-height: 1.8;
  color: #444444;
}

.blog-content h2 {
  color: #333333;
  font-size: 1.8rem;
  margin: 2rem 0 1rem;
  font-weight: 600;
}

.blog-content p {
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
}

.blog-intro {
  font-size: 1.2rem;
  color: #2c3e50;
  font-weight: 500;
  line-height: 1.6;
}

.blog-outro {
  margin-top: 2rem;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 8px;
  font-style: italic;
  color: #4b5320;
}

/* Hover effects */
.blog-title:hover {
  color: #4b5320;
  transition: color 0.3s ease;
}

/* Responsive design */
@media (max-width: 768px) {
  .blog-container {
    margin-top: 60px;
  }

  .blog-post {
    padding: 1.5rem;
  }

  .blog-title {
    font-size: 2rem;
  }

  .blog-meta {
    flex-direction: column;
    gap: 0.5rem;
  }

  .blog-content h2 {
    font-size: 1.5rem;
  }

  .blog-content p {
    font-size: 1rem;
  }
} 