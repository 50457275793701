body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3 {
  font-family: 'Poppins', serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hero-video-container {
  animation: fadeIn 3s ease-in;
}

.hero-button {
  animation: slideIn 3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.Home-Image {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Connect-With-Us-Container {
  background: linear-gradient(45deg, #2a3439, #1b1f22);
  background-size: 400% 400%;
  animation: gradientMove 10s ease infinite;
}

@keyframes gradientMove {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.partner-container img:hover {
  transform: rotate(10deg);
  transition: transform 0.5s;
}
.Connect-With-Us-Container img:hover {
  transform: rotate(10deg);
  transition: transform 0.5s;
}

.page-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #ffffff;
}

.animated-gradient {
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.03) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  animation: rotate 40s linear infinite;
}

.particles {
  position: absolute;
  width: 100%;
  height: 100%;
}

.particle {
  position: absolute;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  animation: float 15s infinite;
}

/* Create multiple particles with different sizes and positions */
.particle:nth-child(1) { width: 50px; height: 50px; left: 10%; top: 20%; animation-delay: 0s; }
.particle:nth-child(2) { width: 30px; height: 30px; left: 30%; top: 40%; animation-delay: 2s; }
.particle:nth-child(3) { width: 40px; height: 40px; left: 50%; top: 60%; animation-delay: 4s; }
.particle:nth-child(4) { width: 25px; height: 25px; left: 70%; top: 80%; animation-delay: 6s; }
.particle:nth-child(5) { width: 35px; height: 35px; left: 90%; top: 30%; animation-delay: 8s; }

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) translateX(0);
  }
  25% {
    transform: translateY(-20px) translateX(10px);
  }
  50% {
    transform: translateY(0) translateX(20px);
  }
  75% {
    transform: translateY(20px) translateX(10px);
  }
}

