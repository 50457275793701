/* Home.css */
.hero-section {
    width: 100%;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    font-style: bold;
    
  }
  
  .hero-video-container {
    position: relative;
    width: 100%; /* Ensure it spans the width of the page */
    background-color: black; /* Black background */
    display: flex;
    justify-content: center; /* Center the video horizontally */
    align-items: center; /* Center the video vertically */
    padding: 20px 0;
  }
  
  iframe {
    display: block; /* Remove default margin */
    max-width: 100%; /* Maximum width of the video */
    width: 100%; /* Responsive width */
    height: 500px; /* Adjust height as needed */
    z-index: 1;
    border:none;
  }
  
  .hero-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, +100px); /* Center button and move it down 15px */
    background-color: black; /* Button background color */
    color: white; /* Text color */
    font-weight: bold; /* Bold text */
    border: 5px solid black; /* 5px black border */
    text-decoration: none; /* Remove underline */
    padding: 10px 20px;
    z-index: 2; /* Ensure button is above the video */
  }

.meet-the-team-title {
  color: #2a3439; /* Same as NavBar text color */
  text-align: center; /* Center the title under the video */
  margin-top: 20px; /* Space below the underline for whatever comes next */
  border-bottom: 2px solid #2a3439;
  
  }

.team-photo {
    width: 800px; /* Set width to 300px */
    height: 500px; /* Set height to 300px */
    display: block; /* Ensures the image is block-level for margin auto to work */
    margin: 20px auto; /* Adds some space and centers the image horizontally */
    border-radius: 10px; /* Optional: Adds rounded corners to the image */
    object-fit: cover; /* Ensures the image covers the area, might crop if aspect ratio differs */
  }
.Who-We-Are-Body{
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 15px;
    text-align: center;
    font-weight: bold;

  }
.Home-Image-container{
    padding: 20px;
    display: flex;
    justify-content: center;
    gap: 100px;
  }

.Home-Image{
    width: 400px;
    height:250px;
    object-fit: cover;
    align-items: center;
    text-align: center;
    padding-left: 20%;
  }
.right-image-container{
  padding-left: 2%;
}

.left-home-title{
    text-align: center;
  }

.right-home-title{
    text-align: center;
  }

.left-image-body{
    text-align: center;
    font-weight: bold;
  }
.right-image-body{
    text-align: center;
    font-weight: bold;
  }


.Connect-With-Us-Container{
    padding: 5px;
    background-color: #2a3439;
    text-align: center;
    width: 100%;
    color: white;
  }

.x-image-button{
    padding: 10px;
    height: 75px;
    width: 75px;
  }

.facebook-image-button{
    padding: 10px;
    height: 75px;
    width: 75px;
  }

.youtube-image-button{
    padding: 10px;
    height: 75px;
    width: 75px;
  }

.Partners-container {
    text-align: center; /* Center the title and partner logos */
  }
  
.Partners-Title {
    margin-bottom: 20px; /* Adds some space between the title and the logos */
  }
  
.partner-container {
    display: flex; /* This enables flexbox layout */
    justify-content: center; /* This centers the flex items (logos) horizontally in the container */
    gap: 20px;
    padding-bottom: 25px; /* This adds some space between each logo */
  }
  
  /* If the images are of different sizes and you want to make them look uniform */
.TST-image-button, .JAS-image-button {
    height: auto; /* Maintain aspect ratio */
    width: auto; /* Adjust based on your preference, could set a max-width */
    max-height: 100px; /* Example fixed height, adjust as needed */
  }
  
  
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
  }
  
.Home-Image {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  
  
.Connect-With-Us-Container {
    background: linear-gradient(45deg, #2a3439, #1b1f22);
    background-size: 400% 400%;
    animation: gradientMove 10s ease infinite;
  }
  
@keyframes gradientMove {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
  }
  
.partner-container img:hover {
    transform: rotate(10deg);
    transition: transform 0.5s;
  }
.Connect-With-Us-Container img:hover {
    transform: rotate(10deg);
    transition: transform 0.5s;
  }
  
